import {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
    getBiWeeklyExecutedPayPeriodSelector,
    getBiWeeklyPayPeriodSelector,
    getMonthlyExecutedPayPeriodSelector,
    getMonthlyPayPeriodSelector,
    getPayFrequencySettingSelector,
    getSemiMonthlyExecutedPayPeriodSelector,
    getSemiMonthlyPayPeriodSelector,
    getWeeklyExecutedPayPeriodSelector,
    getWeeklyPayPeriodSelector,
} from '../redux/selectors/SettingsSelectors'
import {PAYROLL_FREQUENCY, getValidDate} from '../constants/constants'
import {
    getBiWeeklyPayPeriodAction,
    getMonthlyPayPeriodAction,
    getSemiMonthlyPayPeriodAction,
    getWeeklyPayPeriodAction,
} from '../redux/actions/SettingActions'
import {customMoment} from '../customComponents/customMoment/customMoment'
import useBasedOnHost from '../sequiConfig/useBasedOnHost'

const FUTURE_PAY_PERIODS_TILL_MONTHS = 6 // Months

export const PAYROLL_TYPE = {
    open_payroll: 'open_payroll',
    executed_payroll: 'executed_payroll',
}
const usePayFrequency = ({
    payrollType = PAYROLL_TYPE.open_payroll,
    wantFailedPayPeriodAlso = 0,
    autoSelect = true,
    defaultPayPeriodSelection = 'current',
    showClosedPayPeriodsForOpenPayPeriods = false,
    showFinalizedPayPeriodsForOpenPayPeriods = false,
} = {}) => {
    payrollType = payrollType ?? PAYROLL_TYPE.open_payroll
    wantFailedPayPeriodAlso = wantFailedPayPeriodAlso ?? 0
    autoSelect = autoSelect ?? true
    defaultPayPeriodSelection = defaultPayPeriodSelection ?? 'current'
    showClosedPayPeriodsForOpenPayPeriods = showClosedPayPeriodsForOpenPayPeriods ?? false
    showFinalizedPayPeriodsForOpenPayPeriods = showFinalizedPayPeriodsForOpenPayPeriods ?? false

    const dispatch = useDispatch()
    const [selectedPayFrequency, setSelectedPayFrequency] = useState(null)
    const payFrequencyData = useSelector(getPayFrequencySettingSelector)
    const weeklyPayPeriodList = useSelector(getWeeklyPayPeriodSelector)
    const weeklyExecutedPayPeriodList = useSelector(getWeeklyExecutedPayPeriodSelector)
    const monthlyPayPeriodList = useSelector(getMonthlyPayPeriodSelector)
    const monthlyExecutedPayPeriodList = useSelector(getMonthlyExecutedPayPeriodSelector)
    const biWeeklyPayPeriodList = useSelector(getBiWeeklyPayPeriodSelector)
    const biWeeklyExecutedPayPeriodList = useSelector(getBiWeeklyExecutedPayPeriodSelector)
    const semiMonthlyPayPeriodList = useSelector(getSemiMonthlyPayPeriodSelector)
    const semiMonthlyExecutedPayPeriodList = useSelector(getSemiMonthlyExecutedPayPeriodSelector)
    const [selectedPayPeriod, setSelectedPayPeriod] = useState(null)
    const {basedOnHost} = useBasedOnHost()

    const filteredData = useCallback(
        (data, payroll_type) => {
            let finalList = data?.map((item) => ({
                ...item,
                period: `${getValidDate(item?.pay_period_from)} to ${getValidDate(
                    item?.pay_period_to
                )}`,
            }))
            if (basedOnHost?.futureSixPayPeriod && payroll_type == PAYROLL_TYPE.open_payroll) {
                let tillMonthDate = customMoment
                    .momentDate(customMoment.todayDate())
                    .add(FUTURE_PAY_PERIODS_TILL_MONTHS, 'M')
                    ?.toDate()

                finalList = finalList?.filter(
                    (item) =>
                        getValidDate(item?.pay_period_to, 'YYYY/MM/DD 00:00:00', true) <=
                        tillMonthDate
                )
            }
            if (
                !showClosedPayPeriodsForOpenPayPeriods &&
                payroll_type == PAYROLL_TYPE.open_payroll
            ) {
                finalList = finalList?.filter((item) => item?.closed_status != 1)
            }
            if (
                !showFinalizedPayPeriodsForOpenPayPeriods &&
                payroll_type == PAYROLL_TYPE.open_payroll
            ) {
                finalList = finalList?.filter((item) => item?.finalize != 1)
            }

            return finalList
        },
        [
            basedOnHost?.futureSixPayPeriod,
            showClosedPayPeriodsForOpenPayPeriods,
            showFinalizedPayPeriodsForOpenPayPeriods,
        ]
    )

    const getPayPeriodList = useCallback(
        (pay_frequency = null, payroll_type = null) => {
            switch (pay_frequency) {
                case PAYROLL_FREQUENCY.weekly: {
                    if (payroll_type == PAYROLL_TYPE.open_payroll) {
                        if (wantFailedPayPeriodAlso)
                            return filteredData(weeklyPayPeriodList, payroll_type)
                        return filteredData(
                            weeklyPayPeriodList?.filter((item) => !item?.everee_payment_failed),
                            payroll_type
                        )
                    } else if (payroll_type == PAYROLL_TYPE.executed_payroll)
                        return filteredData(weeklyExecutedPayPeriodList, payroll_type)
                    break
                }
                case PAYROLL_FREQUENCY.monthly: {
                    if (payroll_type == PAYROLL_TYPE.open_payroll) {
                        if (wantFailedPayPeriodAlso)
                            return filteredData(monthlyPayPeriodList, payroll_type)
                        return filteredData(
                            monthlyPayPeriodList?.filter((item) => !item?.everee_payment_failed),
                            payroll_type
                        )
                    } else if (payroll_type == PAYROLL_TYPE.executed_payroll)
                        return filteredData(monthlyExecutedPayPeriodList, payroll_type)
                    break
                }
                case PAYROLL_FREQUENCY.biWeekly: {
                    if (payroll_type == PAYROLL_TYPE.open_payroll) {
                        if (wantFailedPayPeriodAlso)
                            return filteredData(biWeeklyPayPeriodList, payroll_type)
                        return filteredData(
                            biWeeklyPayPeriodList?.filter((item) => !item?.everee_payment_failed),
                            payroll_type
                        )
                    } else if (payroll_type == PAYROLL_TYPE.executed_payroll)
                        return filteredData(biWeeklyExecutedPayPeriodList, payroll_type)
                    break
                }
                case PAYROLL_FREQUENCY.semiMonthly: {
                    if (payroll_type == PAYROLL_TYPE.open_payroll) {
                        if (wantFailedPayPeriodAlso)
                            return filteredData(semiMonthlyPayPeriodList, payroll_type)
                        return filteredData(
                            semiMonthlyPayPeriodList?.filter(
                                (item) => !item?.everee_payment_failed
                            ),
                            payroll_type
                        )
                    } else if (payroll_type == PAYROLL_TYPE.executed_payroll)
                        return filteredData(semiMonthlyExecutedPayPeriodList, payroll_type)
                    break
                }
                default: {
                    return []
                }
            }
        },
        [
            biWeeklyExecutedPayPeriodList,
            biWeeklyPayPeriodList,
            filteredData,
            monthlyExecutedPayPeriodList,
            monthlyPayPeriodList,
            semiMonthlyExecutedPayPeriodList,
            semiMonthlyPayPeriodList,
            wantFailedPayPeriodAlso,
            weeklyExecutedPayPeriodList,
            weeklyPayPeriodList,
        ]
    )

    const payPeriodList = useMemo(() => {
        return getPayPeriodList(selectedPayFrequency, payrollType)
    }, [getPayPeriodList, payrollType, selectedPayFrequency])

    const refreshPayPeriodList = useCallback(() => {
        let funcAction = null
        switch (selectedPayFrequency) {
            case PAYROLL_FREQUENCY.weekly: {
                funcAction = getWeeklyPayPeriodAction
                break
            }
            case PAYROLL_FREQUENCY.monthly: {
                funcAction = getMonthlyPayPeriodAction
                break
            }
            case PAYROLL_FREQUENCY.biWeekly: {
                funcAction = getBiWeeklyPayPeriodAction
                break
            }
            case PAYROLL_FREQUENCY.semiMonthly: {
                funcAction = getSemiMonthlyPayPeriodAction
                break
            }
            default:
                break
        }
        if (funcAction) dispatch(funcAction(payrollType))
    }, [dispatch, payrollType, selectedPayFrequency])

    //Changes pay period list according to the selected pay frequency type
    useEffect(() => {
        refreshPayPeriodList()
    }, [selectedPayFrequency])

    // Set Present Pay Period
    useEffect(() => {
        if (payPeriodList?.length > 0) {
            if (defaultPayPeriodSelection == 'current') {
                let presentPeriod = payPeriodList?.find(
                    (item) =>
                        getValidDate(item?.pay_period_from, 'YYYY-MM-DD 00:00', true) <=
                            getValidDate(new Date(), 'YYYY-MM-DD 00:00', true) &&
                        getValidDate(new Date(), 'YYYY-MM-DD 00:00', true) <=
                            getValidDate(item?.pay_period_to, 'YYYY-MM-DD 00:00', true)
                )
                if (presentPeriod) {
                    setSelectedPayPeriod(presentPeriod?.id)
                } else setSelectedPayPeriod(payPeriodList?.[0]?.id)
            } else {
                setSelectedPayPeriod(payPeriodList?.[0]?.id)
            }
        }
    }, [payPeriodList])

    //Return the list of current payPeriod
    const currentPayPeriod = useMemo(() => {
        const currPayPeriod =
            payPeriodList?.length > 0
                ? payPeriodList?.find((item) => item?.id == selectedPayPeriod)
                : null
        return currPayPeriod
    }, [selectedPayPeriod, payPeriodList])

    //Return the list of next payPeriod
    const nextPayPeriod = useMemo(() => {
        const currPayPeriodIndex =
            payPeriodList?.length > 0
                ? payPeriodList?.findIndex((item) => item?.id == selectedPayPeriod)
                : null
        return currPayPeriodIndex > -1
            ? payPeriodList?.find(
                  (item, index) => index > currPayPeriodIndex && item?.closed_status != 1
              )
            : null
    }, [selectedPayPeriod, payPeriodList])

    //Handle Frequency type change
    const onPayFrequencyChange = useCallback(
        (weekType) => {
            let data = payFrequencyData?.find((item) => item?.frequency_type_name == weekType)
            setSelectedPayFrequency(data?.frequency_type_name)
        },
        [payFrequencyData]
    )

    //Create dropdown list of payFrequency type that is enabled
    const frequencyDropdownList = useMemo(() => {
        let frequencies = []
        payFrequencyData?.map((item, index) => {
            // if (item.status && index != 0) {
            if (item.status) {
                frequencies.push({...item, name: item?.frequency_type_name})
            }
        })
        return frequencies
    }, [payFrequencyData])

    useEffect(() => {
        if (frequencyDropdownList?.length > 0 && autoSelect) {
            setSelectedPayFrequency(frequencyDropdownList?.[0]?.frequency_type_name)
        }
    }, [frequencyDropdownList])

    const allPayFrequencyWithOpenPayPeriodList = useMemo(() => {
        return frequencyDropdownList?.reduce((data, item) => {
            data.push({
                ...item,
                pay_periods: getPayPeriodList(item?.frequency_type_name, PAYROLL_TYPE.open_payroll),
            })
            return data
        }, [])
    }, [frequencyDropdownList, getPayPeriodList])

    const allPayFrequencyWithExecutedPayPeriodList = useMemo(() => {
        return frequencyDropdownList?.reduce((data, item) => {
            data.push({
                ...item,
                pay_periods: getPayPeriodList(
                    item?.frequency_type_name,
                    PAYROLL_TYPE.executed_payroll
                ),
            })
            return data
        }, [])
    }, [frequencyDropdownList, getPayPeriodList])

    return {
        getPayPeriodList,
        allPayFrequencyWithOpenPayPeriodList,
        allPayFrequencyWithExecutedPayPeriodList,
        refreshPayPeriodList,
        frequencyDropdownList,
        setSelectedPayFrequency,
        onPayFrequencyChange,
        payPeriodList,
        selectedPayPeriod,
        setSelectedPayPeriod,
        currentPayPeriod,
        nextPayPeriod,
        selectedPayFrequency,
        weeklyPayPeriodList,
        monthlyPayPeriodList,
    }
}

export default usePayFrequency
