import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import _ from 'lodash'
import {AutoComplete} from 'primereact/autocomplete'
import {useSelector} from 'react-redux'
import {getAllStatesAndCitiesSelector} from '../../../redux/selectors/SettingsSelectors'
import {CommonLabel} from '../customInput/CustomInput'
import {MultiSelect} from 'primereact/multiselect'
import CustomLoader from '../../customLoader/CustomLoader'
import './AutocompleteDropdownStyles.css'
import {KTSVG} from '../../../_metronic/helpers'

const CustomAutoCompleteDropdown = ({
    onChange = null,
    value = null,
    filteredValue = null,
    searchValue = null,
    label = null,
    options = [],
    selectedOptions = [],
    displayKey = 'name',
    placeholder = 'Select',
    className,
    required = false,
    valueKey = 'name',
    showSelectAll = true,
    placeholderIcon = 'bi bi-plus-square',
    errorMessage = '',
    name = null,
    showSelectAllCheckBox = true,
    loading = false,
    id = null,
    display = 'chip',
}) => {
    const [filteredValues, setFilteredValues] = useState(null)

    const onSearch = useCallback(
        (event) => {
            setTimeout(() => {
                let _filteredOptions
                if (!event?.query?.trim().length) {
                    _filteredOptions = [...options]
                } else {
                    _filteredOptions = options?.filter((item) => {
                        return item?.[valueKey]?.toLowerCase().startsWith(event.query.toLowerCase())
                    })
                }

                setFilteredValues(_filteredOptions)
            }, 250)
        },
        [options]
    )

    return (
        <div className={`${className} d-flex flex-column gap-6px`}>
            <CommonLabel label={label} required={required} />
            <div className='w-100' style={{position: 'relative'}}>
                <CustomLoader full visible={loading} />
                <MultiSelect
                    id={id}
                    className={`${errorMessage ? 'p-invalid' : ''} w-100`}
                    filter
                    display={display}
                    value={selectedOptions}
                    options={options}
                    showSelectAll={showSelectAll}
                    optionLabel={valueKey}
                    onChange={onChange}
                    placeholder={
                        <div className='filter-placeholder'>
                            <i className={placeholderIcon} style={{marginRight: '5px'}}></i>
                            {placeholder}
                        </div>
                    }
                    name={name}
                    dropdownIcon={(opts) => {
                        return opts.iconProps['data-pr-overlay-visible'] ? (
                            <KTSVG
                                path='/media/icons/duotune/art/dropdownUpIcon.svg'
                                svgClassName='w-20px h-20px'
                            />
                        ) : (
                            <KTSVG
                                path='/media/icons/duotune/art/dropdownDownIcon.svg'
                                svgClassName='w-20px h-20px'
                            />
                        )
                    }}
                />
            </div>
            <div className='text-sm-regular text-sqError500'>{errorMessage}</div>
        </div>
    )
}

export default CustomAutoCompleteDropdown
