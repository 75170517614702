import React from 'react'

import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton2'

const QuickLinksCardStandard = () => {
    const navigate = useNavigate()
    const navigateToQuickLink = (path) => {
        navigate(path)
    }
    return (
        <>
            <div className='bg-cmwhite sqcard-shadow p-18px pb-0' style={{borderRadius: 10}}>
                <div className='mb-6 text-sqGray900 text-md-bold'>Quick Links</div>
                <div className='d-flex flex-md-row gap-3 overflow-auto text-nowrap align-items-center  pb-18px'>
                    <div className='flex-grow-1 w-180px'>
                        <CustomButton2
                            id={'Announcement_Quick_Links_Current_Pay_Stub_Btn'}
                            icon={
                                <KTSVG
                                    path='/media/icons/duotune/art/podium.svg'
                                    svgClassName='w-20px h-20px'
                                />
                            }
                            buttonLabel={'Leaderboards'}
                            onClick={() => navigateToQuickLink('/standard-dashboard/leaderboards')}
                            width={100}
                            buttonType={BUTTON_TYPE.secondaryColor}
                            buttonSize={BUTTON_SIZE.lg}
                        />
                    </div>
                    {/* 2 */}
                    <div className='flex-grow-1 w-180px'>
                        <CustomButton2
                            id={'Announcement_Quick_Links_Current_Pay_Stub_Btn'}
                            buttonLabel='Current Pay stub'
                            onClick={() => navigateToQuickLink('/mysales/pay-stubs')}
                            width={'100'}
                            buttonType={BUTTON_TYPE.secondaryColor}
                            buttonSize={BUTTON_SIZE.lg}
                        />
                    </div>
                    {/* 3 */}
                    <div className='flex-grow-1 w-180px'>
                        <CustomButton2
                            id={'Announcement_Quick_Links_My_Requests_Btn'}
                            buttonLabel='My Requests'
                            onClick={() => navigateToQuickLink('/requests/request')}
                            width={100}
                            buttonType={BUTTON_TYPE.secondaryColor}
                            buttonSize={BUTTON_SIZE.lg}
                        />
                    </div>
                    {/* 4 */}
                    <div className='flex-grow-1 w-180px'>
                        <CustomButton2
                            id={'Announcement_Quick_Links_Recent_Sales_Btn'}
                            buttonLabel='Recent Sales'
                            onClick={() => navigateToQuickLink('/mysales/sales?recentSale')}
                            width={100}
                            buttonType={BUTTON_TYPE.secondaryColor}
                            buttonSize={BUTTON_SIZE.lg}
                        />
                    </div>
                    {/* 5 */}
                    <div className='flex-grow-1 w-180px'>
                        <CustomButton2
                            id={'Announcement_Quick_Links_My_Overrides_Btn'}
                            buttonLabel='My Overrides'
                            onClick={() => navigateToQuickLink('/mysales/my-overrides')}
                            buttonType={BUTTON_TYPE.secondaryColor}
                            buttonSize={BUTTON_SIZE.lg}
                            width={100}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuickLinksCardStandard
