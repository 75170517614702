import {memo, useMemo, useState} from 'react'
import {InputText} from 'primereact/inputtext'
import {InputMask} from 'primereact/inputmask'
import {InputTextarea} from 'primereact/inputtextarea'
import {numberInputOnWheelPreventChange} from '../../../helpers/CommonHelpers'
import {fontsFamily} from '../../../assets/fonts/fonts'
import {Chips} from 'primereact/chips'
import {InputIcon} from 'primereact/inputicon'
import './styles.css' // Import the custom CSS
import {KTSVG} from '../../../_metronic/helpers'
import Show from '../../show/Show'
import CustomTooltip from '../../customTooltip/CustomTooltip'

export const INPUT_TYPE = {
    text: 'text',
    number: 'number',
    mobile: 'mobile',
    currency: 'currency',
    textarea: 'textarea',
    email: 'email',
    password: 'password',
    search: 'search',
    ChipText: 'ChipText',
    maskInput: 'maskInput',
}

const CustomInput = ({
    // Number Input
    min = null,
    max = null,
    label = null,
    hideLabel = false,
    // Common
    readOnly = false,
    required = false,
    value = null,
    onClick = null,
    onChange = null,
    type = INPUT_TYPE.text,
    width,
    containerWidth = 'w-100',
    placeholder = type === INPUT_TYPE.search ? 'Search' : '',
    disabled = false,
    className = '',
    style,
    errorMessage = '',
    name = '',
    rejex = null,
    feedback = true,
    mask = '(999) 999-9999',
    iconClass = null,
    subLabel = null,
    prefixText = '',
    suffixText = '',
    suffixContent = null,
    onSubmit = null,
    ref = null,
    subLabelClass = '',
    inputBackground = 'cmwhite',
    textColor = '',
    onKeyPress = null,
    id,
    labelStyle = null,
    labelColor = null,
    restrictNegativeNum = false,
    onBlur = () => {},
    helpText = null,
}) => {
    const [showPassword, setShowPassword] = useState(true)
    const [isActive, setIsActive] = useState(false)

    const handleFocus = () => {
        setIsActive(true)
    }

    const handleBlur = () => {
        setIsActive(false)
        onBlur()
    }

    const commonProps = useMemo(
        () => ({
            className: `disable-focus ${className} text-sqGray900 w-100 border-0 p-0 py-10px text-md-regular bg-cmwhite`,
            onKeyPress,
            onChange,
            readOnly,
            onClick,
            value: value ?? '',
            placeholder,
            name,
            disabled,
            style: {
                ...style,
            },
        }),
        [
            className,
            disabled,
            name,
            onChange,
            onClick,
            onKeyPress,
            placeholder,
            readOnly,
            style,
            value,
        ]
    )

    const NumberInput = useMemo(
        () => (
            <div
                className={`d-flex flex-column gap-1 ${width}`}
                style={{fontFamily: 'Manrope', fontSize: '14px', fontWeight: '600'}}
            >
                <CommonLabel
                    label={label}
                    subLabelClass={subLabelClass}
                    hideLabel={hideLabel}
                    subLabel={subLabel}
                    required={required}
                />
                <div className='d-flex align-items-center gap-1'>
                    <PrefixSuffixComponent
                        errorMessage={errorMessage}
                        suffixContent={suffixContent}
                        suffixText={suffixText}
                        prefixText={prefixText}
                        isActive={isActive}
                        helpText={helpText}
                    >
                        <InputText
                            id={id}
                            type={'number'}
                            {...commonProps}
                            min={min}
                            max={max}
                            onfo
                            keyfilter={restrictNegativeNum ? /^[+]?\d*\.?\d*$/ : 'num'} // only accept number, decimal no and '-' sign
                            onWheel={numberInputOnWheelPreventChange}
                            onSubmit={onSubmit}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </PrefixSuffixComponent>
                </div>
            </div>
        ),
        [
            commonProps,
            errorMessage,
            handleBlur,
            helpText,
            hideLabel,
            id,
            isActive,
            label,
            max,
            min,
            onSubmit,
            prefixText,
            required,
            restrictNegativeNum,
            subLabel,
            subLabelClass,
            suffixContent,
            suffixText,
            width,
        ]
    )

    const MobileInput = useMemo(
        () => (
            <div className='d-flex flex-column gap-1'>
                <CommonLabel label={label} subLabel={subLabel} required={required} />
                <PrefixSuffixComponent
                    suffixText={suffixText}
                    prefixText={
                        <KTSVG
                            path='/media/icons/duotune/art/phone.svg'
                            svgClassName='w-20px h-20px'
                        />
                    }
                    errorMessage={errorMessage}
                    isActive={isActive}
                    showBorder={false}
                    helpText={helpText}
                >
                    <InputMask
                        id={id}
                        {...commonProps}
                        mask={mask}
                        autoClear={false}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                </PrefixSuffixComponent>
            </div>
        ),
        [
            helpText,
            commonProps,
            errorMessage,
            handleBlur,
            id,
            isActive,
            label,
            mask,
            prefixText,
            required,
            subLabel,
            suffixText,
        ]
    )

    const MaskInput = useMemo(
        () => (
            <div className='d-flex flex-column gap-1'>
                <CommonLabel label={label} subLabel={subLabel} required={required} />
                <PrefixSuffixComponent
                    suffixText={suffixText}
                    prefixText={prefixText}
                    errorMessage={errorMessage}
                    isActive={isActive}
                    showBorder={false}
                    helpText={helpText}
                >
                    <InputMask
                        id={id}
                        {...commonProps}
                        mask={mask}
                        autoClear={false}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                </PrefixSuffixComponent>
            </div>
        ),
        [
            helpText,
            commonProps,
            errorMessage,
            handleBlur,
            id,
            isActive,
            label,
            mask,
            prefixText,
            required,
            subLabel,
            suffixText,
        ]
    )

    const TextInput = useMemo(
        () => (
            <div className='d-flex flex-column gap-6px w-100'>
                <CommonLabel
                    label={label}
                    labelColor={labelColor}
                    labelStyle={labelStyle}
                    hideLabel={hideLabel}
                    subLabel={subLabel}
                    required={required}
                />
                <PrefixSuffixComponent
                    prefixText={prefixText}
                    suffixText={suffixText}
                    errorMessage={errorMessage}
                    isActive={isActive}
                    helpText={helpText}
                >
                    <InputText
                        id={id}
                        {...commonProps}
                        type={type}
                        keyfilter={rejex}
                        onKeyPress={onKeyPress}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />{' '}
                </PrefixSuffixComponent>
            </div>
        ),
        [
            helpText,
            commonProps,
            errorMessage,
            handleBlur,
            hideLabel,
            id,
            isActive,
            label,
            labelColor,
            labelStyle,
            onKeyPress,
            prefixText,
            rejex,
            required,
            subLabel,
            suffixText,
            type,
        ]
    )

    const EmailInput = useMemo(
        () => (
            <div className='d-flex flex-column gap-1 w-100'>
                <CommonLabel
                    label={label}
                    labelColor={labelColor}
                    labelStyle={labelStyle}
                    hideLabel={hideLabel}
                    subLabel={subLabel}
                    required={required}
                />

                <PrefixSuffixComponent
                    prefixText={
                        <KTSVG
                            path='/media/icons/duotune/art/mail-input-icon.svg'
                            svgClassName='w-20px h-20px'
                        />
                    }
                    suffixText={suffixText}
                    errorMessage={errorMessage}
                    isActive={isActive}
                    showBorder={false}
                    helpText={helpText}
                >
                    <InputText
                        id={id}
                        {...commonProps}
                        type={type}
                        keyfilter={rejex}
                        onKeyPress={onKeyPress}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />{' '}
                </PrefixSuffixComponent>
            </div>
        ),
        [
            commonProps,
            errorMessage,
            handleBlur,
            helpText,
            hideLabel,
            id,
            isActive,
            label,
            labelColor,
            labelStyle,
            onKeyPress,
            rejex,
            required,
            subLabel,
            suffixText,
            type,
        ]
    )

    const ChipText = useMemo(
        () => (
            <div className='d-flex flex-column gap-1 w-100'>
                <CommonLabel
                    label={label}
                    hideLabel={hideLabel}
                    subLabel={subLabel}
                    required={required}
                />
                <PrefixSuffixComponent
                    prefixText={prefixText}
                    suffixText={suffixText}
                    errorMessage={errorMessage}
                    isActive={isActive}
                    helpText={helpText}
                >
                    <Chips
                        id={id}
                        {...commonProps}
                        type={type}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </PrefixSuffixComponent>
            </div>
        ),
        [
            commonProps,
            errorMessage,
            handleBlur,
            helpText,
            hideLabel,
            id,
            isActive,
            label,
            prefixText,
            required,
            subLabel,
            suffixText,
            type,
        ]
    )

    const SearchInput = useMemo(
        () => (
            <div className='d-flex flex-column gap-1 w-100 '>
                <CommonLabel
                    label={label}
                    hideLabel={hideLabel}
                    subLabel={subLabel}
                    required={required}
                    iconClass={iconClass}
                />
                <PrefixSuffixComponent
                    prefixText={
                        <KTSVG
                            path='/media/icons/duotune/art/search.svg'
                            svgClassName='w-20px h-20px'
                        />
                    }
                    suffixText={suffixText}
                    errorMessage={errorMessage}
                    isActive={isActive}
                    showBorder={false}
                    helpText={helpText}
                >
                    <InputText
                        id={id}
                        {...commonProps}
                        type={type}
                        keyfilter={rejex}
                        onKeyPress={onKeyPress}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />{' '}
                </PrefixSuffixComponent>
            </div>
        ),
        [
            commonProps,
            errorMessage,
            handleBlur,
            helpText,
            hideLabel,
            iconClass,
            id,
            isActive,
            label,
            onKeyPress,
            rejex,
            required,
            subLabel,
            suffixText,
            type,
        ]
    )

    const TextArea = useMemo(
        () => (
            <div className='d-flex flex-column gap-1'>
                <CommonLabel
                    label={label}
                    hideLabel={hideLabel}
                    subLabel={subLabel}
                    required={required}
                />
                {/* <PrefixSuffixComponent
                    prefixText={prefixText}
                    suffixText={suffixText}
                    isActive={isActive}
                    helpText={helpText}
                    inputType={type}
                > */}
                <InputTextarea
                    id={id}
                    {...commonProps}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={`${errorMessage ? 'input_error' : 'custom-border'} ${
                        isActive ? 'focus' : ''
                    }`}
                    style={{
                        borderRadius: '10px',
                        fontWeight: 600,
                        fontFamily: 'Manrope',
                        fontSize: '14px',
                        width: '100%',
                        ...style,
                    }}
                    autoResize
                />{' '}
                {/* </PrefixSuffixComponent> */}
            </div>
        ),
        [
            commonProps,
            errorMessage,
            handleBlur,
            hideLabel,
            id,
            isActive,
            label,
            required,
            style,
            subLabel,
        ]
    )

    const TextPassword = useMemo(
        () => (
            <div className='d-flex flex-column gap-1'>
                <CommonLabel
                    label={label}
                    labelColor={labelColor}
                    labelStyle={labelStyle}
                    hideLabel={hideLabel}
                    subLabel={subLabel}
                    required={required}
                />

                <PrefixSuffixComponent
                    suffixText={
                        <i
                            onClick={() => setShowPassword(!showPassword)}
                            className={`${
                                showPassword
                                    ? 'fa-regular fa-eye fs-4 text-cmGrey600 text-hover-dark'
                                    : 'fa-regular fa-eye-slash fs-4 text-cmGrey600 text-hover-dark'
                            } cursor-pointer`}
                        />
                    }
                    errorMessage={errorMessage}
                    isActive={isActive}
                    helpText={helpText}
                >
                    <InputText
                        id={id}
                        {...commonProps}
                        type={showPassword ? 'password' : 'text'}
                        keyfilter={rejex}
                        onKeyPress={onKeyPress}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />{' '}
                </PrefixSuffixComponent>
            </div>
        ),
        [
            helpText,
            commonProps,
            errorMessage,
            handleBlur,
            hideLabel,
            id,
            isActive,
            label,
            labelColor,
            labelStyle,
            onKeyPress,
            rejex,
            required,
            showPassword,
            subLabel,
        ]
    )

    const CustomisedInputs = useMemo(() => {
        switch (type) {
            case INPUT_TYPE.text:
                return TextInput
            case INPUT_TYPE.number:
                return NumberInput
            case INPUT_TYPE.currency:
                return NumberInput
            case INPUT_TYPE.mobile:
                return MobileInput
            case INPUT_TYPE.textarea:
                return TextArea
            case INPUT_TYPE.email:
                return EmailInput
            case INPUT_TYPE.password:
                return TextPassword
            case INPUT_TYPE.search:
                return SearchInput
            case INPUT_TYPE.ChipText:
                return ChipText
            case INPUT_TYPE.maskInput:
                return MaskInput
            default:
                return TextInput
        }
    }, [
        ChipText,
        EmailInput,
        MobileInput,
        NumberInput,
        SearchInput,
        TextArea,
        TextInput,
        TextPassword,
        type,
        MaskInput,
    ])

    return (
        <div className={`${containerWidth} d-flex flex-column gap-6px`}>
            <div>{CustomisedInputs}</div>
            <Show>
                <Show.When isVisible={errorMessage ? true : false}>
                    <div className='text-sqError500 text-sm-regular'>{errorMessage}</div>
                </Show.When>
            </Show>
        </div>
    )
}

export const CommonLabel = ({
    hideLabel = false,
    label = '',
    subLabel = '',
    iconClass = '',
    required = false,
    subLabelClass = '',
    labelStyle = null,
    labelColor = null,
}) => (
    <>
        {label ? (
            <label
                className={`text-sm-medium text-${labelColor ?? 'sqGray700'} ${
                    required ? 'required' : null
                }`}
                style={{
                    opacity: hideLabel ? 0 : 1,
                    ...labelStyle,
                }}
            >
                <span className={iconClass} /> {label}
                {subLabel ? (
                    <span className={`ms-2 text-xs-regular text-sqGray700 ${subLabelClass}`}>
                        {subLabel}
                    </span>
                ) : null}
            </label>
        ) : null}
    </>
)

// main
export const PrefixSuffixComponent = ({
    prefixText,
    suffixText,
    children,
    errorMessage,
    isActive,
    showBorder = true,
    helpText,
    inputType = null,
}) => (
    <div
        className={`px-14px overflow-hidden p-inputgroup ${
            errorMessage ? 'input_error' : 'custom-border'
        } ${isActive ? 'focus' : ''} `}
    >
        {prefixText ? (
            <>
                <span
                    className={`d-flex flex-center text-sqGray500 bg-white text-md-regular  ${
                        showBorder ? 'pe-14px me-14px border-end border-sqGray300' : 'pe-2'
                    }`}
                >
                    {prefixText}
                </span>
            </>
        ) : null}
        {children}
        <div className='d-flex flex-center'>
            {suffixText ? (
                <span className='d-flex flex-center text-sqGray500 bg-white text-md-regular ps-2'>
                    {suffixText}
                </span>
            ) : null}

            {/* -------------------- Error icon -------------------- */}
            {errorMessage && inputType != INPUT_TYPE.textarea ? (
                <span className='d-flex flex-center ps-2'>
                    <KTSVG
                        path='/media/icons/duotune/art/alert-circle.svg'
                        svgClassName='w-14px h-14px'
                    />
                </span>
            ) : null}

            {/* -------------------- Help text -------------------- */}
            {helpText ? (
                <span className='d-flex flex-center ps-2'>
                    <CustomTooltip className='' title={helpText}>
                        <KTSVG
                            path='/media/icons/duotune/art/help-circle.svg'
                            svgClassName='w-14px h-14px cursor-pointer'
                        />
                    </CustomTooltip>
                </span>
            ) : null}
        </div>
    </div>
)

export default memo(CustomInput)
